import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Products from './components/Products';
import Services from './components/Services';
import SocialMedia from './components/SocialMedia';
import VideoPlayer from './components/VideoPlayer';

function App() {
	return (
		<div id='home'>
			<Navbar />
			<VideoPlayer
				source={
					'https://res.cloudinary.com/dzwllpun3/video/upload/v1662599125/AM%20Studios/amTeaser_tlrcv2.mp4'
				}
				muted={true}
				playing={true}
			/>
			<Services />
			<Products />
			<Contact />
			<SocialMedia />
		</div>
	);
}

export default App;
