import {
	Tabs,
	TabsHeader,
	TabsBody,
	Tab,
	TabPanel,
} from '@material-tailwind/react';

import VideoPlayer from './VideoPlayer';

const Products = () => {
	const data = [
		{
			label: 'Música para Spots',
			value: 'musicSpots',
			source:
				'https://res.cloudinary.com/dzwllpun3/video/upload/v1662599153/AM%20Studios/amMusicSpots_tzwnbj.mp4',
		},
		{
			label: 'Diseño Sonoro',
			value: 'soundDesign',
			source:
				'https://res.cloudinary.com/dzwllpun3/video/upload/v1662599177/AM%20Studios/disenoSonoroVideo_qpi2vr.mp4',
		},

		{
			label: 'Doblaje',
			value: 'doblaje',
			source:
				'https://res.cloudinary.com/dzwllpun3/video/upload/v1662599177/AM%20Studios/disenoSonoroVideo_qpi2vr.mp4',
		},
	];

	return (
		<div className='w-[85%] mx-auto mt-20'>
			<Tabs id='custom-animation' value='musicSpots'>
				<TabsHeader>
					{data.map(({ label, value }) => (
						<Tab key={value} value={value} className='font-main h-full'>
							{label}
						</Tab>
					))}
				</TabsHeader>
				<TabsBody
					animate={{
						mount: { y: 0 },
						unmount: { y: 250 },
					}}
				>
					{data.map(({ value, source }) => (
						<TabPanel key={value} value={value}>
							<VideoPlayer source={source} muted={false} playing={false} />
						</TabPanel>
					))}
				</TabsBody>
			</Tabs>
		</div>
	);
};

export default Products;
