import Title from './Title';

const Contact = () => {
	return (
		<div id='contact' className='mt-14 mb-20 lg:mt-20'>
			<Title title={'Contacto'} line />
			<div className='flex flex-col lg:flex-row lg:justify-center lg:items-center bg-secondary-red 2xl:py-5 2xl:text-xl'>
				<div className='text-white text-center my-7 lg:w-1/3 lg:my-32'>
					<p className='font-main uppercase font-extrabold tracking-widest mb-4'>
						Email
					</p>
					<p className='font-main text-slate-300'>
						<a
							href='mailto:contacto@am-studios.com.mx'
							target={'_blank'}
							rel='noreferrer'
						>
							contacto@am-studios.com.mx
						</a>
					</p>
				</div>
				<div className='text-white text-center my-7 lg:w-1/3 lg:my-32'>
					<p className='font-main uppercase font-extrabold tracking-widest mb-4'>
						Teléfono
					</p>
					<p className='font-main text-slate-300'>
						<a href='tel:+527752000296'>+52 775 200 0296</a>
					</p>
				</div>
				<div className='text-white text-center my-7 lg:w-1/3 lg:my-32'>
					<p className='font-main uppercase font-extrabold tracking-widest mb-4'>
						Oficina
					</p>
					<p className='font-main text-slate-300'>Puebla, Pue.</p>
				</div>
			</div>
		</div>
	);
};

export default Contact;
