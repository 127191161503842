import Card from './Card';
import Title from './Title';

const services = [
	{
		id: 'locucion',
		name: 'Locución',
		description:
			'Brinda a tu anuncio, podcats o producción el color de voz que buscas',
		image:
			'https://res.cloudinary.com/dzwllpun3/image/upload/v1663270387/AM%20Studios/locucion_d1fek3.png',
	},
	{
		id: 'diseñoSonoro',
		name: 'Diseño Sonoro',
		description:
			'Complementa el ambiente sonoro de tus producciones audiovisuales y brindarles espacialidad y tiempo.',
		image:
			'https://res.cloudinary.com/dzwllpun3/image/upload/v1663270386/AM%20Studios/disenoSonoro_fa6tvj.png',
	},
	{
		id: 'musicaSpots',
		name: 'Música para Spots',
		description: 'Dale a tus anuncios el soporte musical que necesita ',
		image:
			'https://res.cloudinary.com/dzwllpun3/image/upload/v1663270386/AM%20Studios/musicaSpots_djbf60.png',
	},
	{
		id: 'branding',
		name: 'Branding',
		description:
			'Jingles, Musicalización, Firmas Sonoras. Nosotros podemos proporcionar una identidad sonora a tu marca.',
		image:
			'https://res.cloudinary.com/dzwllpun3/image/upload/v1663270385/AM%20Studios/branding_fm93lw.png',
	},
];

const Services = () => {
	return (
		<div id='services' className='mx-auto my-14'>
			<Title title={'Servicios'} line />
			<div className='flex flex-wrap justify-evenly gap-10 lg:gap-5 mx-10 mt-10'>
				{services.map((service) => (
					<Card
						key={service.id}
						image={service.image}
						title={service.name}
						description={service.description}
					/>
				))}
			</div>
		</div>
	);
};

export default Services;
