import React from 'react';

const Title = ({ title, line }) => {
	return (
		<div className='relative flex flex-col justify-center items-center'>
			<h1 className='text-xl md:text-2xl lg:text-3xl uppercase text-white font-main font-semibold'>
				{title}
			</h1>
			{line && <div className='relative w-16 h-1 my-4 bg-secondary-red' />}
		</div>
	);
};

export default Title;
