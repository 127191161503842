import ReactPlayer from 'react-player';

const VideoPlayer = ({ source, muted, playing }) => {
	return (
		<div
			id='video'
			className='flex justify-center aspect-video mx-auto mt-5 lg:mt-10 mb-10 w-[80%] max-w-[900px]'
		>
			<ReactPlayer
				url={source}
				muted={muted}
				controls
				playing={playing}
				volume={1}
				width={'100%'}
				height={'100%'}
			/>
		</div>
	);
};

export default VideoPlayer;
