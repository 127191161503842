const Card = ({ image, title, description }) => {
	return (
		<>
			<div className='max-w-[18rem] rounded overflow-hidden shadow-lg bg-[#434141]'>
				<img className='w-full' src={image} alt='Sunset in the mountains' />
				<div className='px-6 py-4'>
					<h2 className='font-bold text-blue-gray-100 text-xl mb-2'>{title}</h2>
					<p className='text-blue-gray-100 text-base'>{description}</p>
				</div>
			</div>
		</>
	);
};

export default Card;
