import { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaTimes } from 'react-icons/fa';
import Title from './Title';

const menuOptions = [
	{
		id: 'home',
		name: 'Inicio',
	},
	{
		id: 'services',
		name: 'Servicios',
	},
	{
		id: 'us',
		name: 'Nosotros',
	},
	{
		id: 'contact',
		name: 'Contacto',
	},
];

const Navbar = () => {
	const [showMenu, setShowMenu] = useState(false);

	return (
		<div className='text-white px-10 py-1 bg-main sticky z-50 top-0'>
			<div className='hidden md:flex md:justify-between items-center'>
				<div className='w-20'>
					<a href='#home'>
						<img
							src={
								'https://res.cloudinary.com/dzwllpun3/image/upload/v1663270385/AM%20Studios/AMrojo_hpm3ys.png'
							}
							alt='AM Studios Logo'
						/>
					</a>
				</div>
				<ul className='flex justify-between'>
					{menuOptions.map((option) => (
						<li
							key={option.id}
							className='mx-8 lg:mx-12 font-main text-white font-bold cursor-pointer navbar-menu-item uppercase'
						>
							<a href={`#${option.id}`}>{option.name}</a>
						</li>
					))}
				</ul>
				<div className='w-20'>
					<a href='#home'>
						<img
							src={
								'https://res.cloudinary.com/dzwllpun3/image/upload/v1663270385/AM%20Studios/AMrojo_hpm3ys.png'
							}
							alt='AM Studios Logo'
						/>
					</a>
				</div>
			</div>
			<div className='flex justify-between items-center md:hidden'>
				<div className='w-16'>
					<img
						src={
							'https://res.cloudinary.com/dzwllpun3/image/upload/v1663270385/AM%20Studios/AMrojo_hpm3ys.png'
						}
						alt='Am Studios Logo'
					/>
				</div>
				<Title title='AM Studios' />
				{!showMenu ? (
					<GiHamburgerMenu
						className='text-white cursor-pointer transition-all duration-[250ms]'
						size={24}
						onClick={() => setShowMenu(!showMenu)}
					/>
				) : (
					<FaTimes
						className='text-white cursor-pointer transition-all duration-[250ms] absolute z-20 top-6 right-10'
						size={24}
						onClick={() => setShowMenu(!showMenu)}
					/>
				)}
			</div>
			{showMenu && (
				<div className='transition-all duration-[250ms] animate-fade-in bg-main absolute top-0 left-0 w-full z-10 md:hidden'>
					<ul className='mt-12'>
						{menuOptions.map((option) => (
							<li
								key={option.id}
								onClick={() => setShowMenu(false)}
								className='cursor-pointer font-main my-4 py-4 px-8 text-white font-bold hover:text-xl hover:text-secondary-red transition-all duration-[250ms] uppercase'
								// onMouseOver={() => console.log(option)}
							>
								<a href={`#${option.id}`}>{option.name}</a>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default Navbar;
