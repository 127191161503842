import { FaYoutube, FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';
import Title from './Title';

const socialMedia = [
	{
		id: 'yt',
		name: 'YouTube',
		icon: FaYoutube,
		url: 'https://www.youtube.com/channel/UCFr6NlV-ay3MQg9VmLYPrtw',
	},
	{
		id: 'ig',
		name: 'Instagram',
		icon: FaInstagram,
		url: 'https://www.instagram.com/am_studios_mx/',
	},
	{
		id: 'fb',
		name: 'Facebook',
		icon: FaFacebook,
		url: 'https://www.facebook.com/profile.php?id=100067330069126',
	},
	{
		id: 'tw',
		name: 'Twitter',
		icon: FaTwitter,
		url: 'https://twitter.com',
	},
];

const SocialMedia = () => {
	return (
		<div id='redesSociales' className='lg:mb-10'>
			<Title title='Redes Sociales' line />
			<div className='flex flex-wrap justify-evenly md:flex-row md:justify-between md:mx-12 lg:mx-36 my-8'>
				{socialMedia.map((media) => (
					<div
						key={media.id}
						className={`flex justify-center items-center h-28 w-28 md:h-32 md:w-32 m-10 md:m-5 social-media social-media-${media.id} bg-[#434141] rotate-45 transition-colors cursor-pointer hover:bg-secondary-red`}
					>
						<a href={media.url} target='blank' className='-rotate-45'>
							<media.icon color='#fff' className='text-[50px]' />
						</a>
					</div>
				))}
			</div>
		</div>
	);
};

export default SocialMedia;
